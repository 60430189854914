import React from 'react'
import { Box, Button, Flex, Spacer } from '@chakra-ui/react'
import logo from './assets/images/LOGOLETRA.png'; 
import menuburger from './assets/images/menu.png'; 
import BalginBold from './fonts/BalginBold.ttf';

const NavBar = ({accounts, setAccounts}) =>  {
    
    const isConnected = Boolean(accounts[0]);
    localStorage.setItem('wallet', accounts[0]);
    async function connectAccount(){
        if (window.ethereum){
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            setAccounts(accounts);
        }
    }

    return (
        <div>
            <Flex width="100%" height="0" backgroundColor="red">

            </Flex>
            <Flex width="100%" justify="center" align="center" padding="20px" paddingBottom="10" display="flex" alignItems="center" alignSelf="center">
                <Flex justify="center" align="center" alignItems="center" alignSelf="center" width="100%" fontSize="24px">
                    <Spacer />
                    <Box margin="0 15px"><a class="hide link" href="#roadmap">Roadmap</a></Box>
                    <Spacer />
                    <Box margin="0 15px"><a class="hide link" href="#team">Team  </a></Box>
                    
                    <Spacer />
                    <Box margin="0 15px"><a class="hide link" href="#faq">FAQ</a></Box>
                    <Spacer />
                    <img src={logo} id="logoletra" class="logoletra" alt="Logo" />
                    <Spacer />
                    <Box margin="0 15px"><a class="hide link" href="#about">About  </a></Box>
                    <Spacer />
                    <Box margin="0 15px"><a class="hide link" href="#rarity">Rarity</a></Box>
                    <Spacer />
                    <Box margin="0 15px"><a class="hide link" href="#socials">Socials  </a></Box>
                    <img src={menuburger} id="menuburger" class="menuburger" alt="Menu" />
                    <Spacer />
                </Flex>
            </Flex> 
            <Flex width="100%" justify="center" align="center" padding="20px" paddingBottom="10" display="flex" alignItems="center" alignSelf="center">
                <Flex justify="center" align="center" alignItems="center" alignSelf="center" width="100%">
                {isConnected ? (
                    <Box margin="0 20px" color="#649f5f" borderColor="#ccf2fe" borderWidth="2px" fontFamily="BalginBold">Connected</Box>
                ): (
                    <Button
                        onClick={connectAccount}
                        backgroundColor="#649f5f"
                        borderRadius="25px"
                        borderWidth="0"
                        color="white"
                        height="15px"
                        width="120px"
                        cursor="pointer"
                        fontFamily="inherit"
                        paddingTop="15px"
                        paddingBottom="15px"
                        margin="0 15px"
                    > Connect </Button>
                    )} 
                </Flex>
            </Flex>
                    
        </div> 
    );
}

export default NavBar;