import { useState } from 'react';
import { ethers, BigNumber} from 'ethers';
import TheTurtleClub from './TheTurtleClub.json';
import {Flex,Button, Link} from '@chakra-ui/react';
import Roadmap from "./assets/images/roadmap.gif"
import shells from "./assets/images/Iso_sombra.png"

const  theTurtleClubAddress = "0xDEF5824c684d71B3a402BA7bd95b3d710E928956";

const MainMint = ({ accounts, setAddress, setAccounts}) => {
    function uint16(v) {
        return v & 0xFFFF;
      }
    const[_mintAmount, setMintAmount] = useState(1);
    localStorage.setItem('mint', _mintAmount);
    var x = new Uint16Array(1)
    x[0] = _mintAmount
    console.log("START")
    console.log(x[0]);
    console.log(x.byteLength);
    console.log("Amount changed: " + uint16(_mintAmount));
    
    const isConnected = Boolean(accounts[0]);

    // case 1 og
    // case 2 whitelist
    // case 3 public
    var foo = 0;

    var qty;
    var sale;
    function Load(){
        if (foo===1){
            qty=1;
            sale="OG";
        }
        else if(foo===2){
            qty=1;
            sale="Whitelist";
        }
        else if(foo===3){
            qty=3;
            sale="Public";
        }
        console.log(sale + " mint - Max: " + qty);
    }

    async function handleMint() {
        if (window.ethereum){
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
                theTurtleClubAddress,
                TheTurtleClub.abi,
                signer
            );
            try {
                switch (foo){
                    case 1:
                        const response1 = await contract.OGMint(BigNumber.from(_mintAmount));
                        break;
                    case 2:
                        const response2 = await contract.whitelistMint(BigNumber.from(_mintAmount));
                        break;
                    case 3:
                        const response3 = await contract.mint(uint16(_mintAmount));
                        
                        
                        break;
                }
            } catch (err){
                console.log("error: ", err)
            }
        }
    } 

    const handleDecrement = () => {
        Load()
        if (_mintAmount <= 1) return;
        setMintAmount(_mintAmount - 1);
    }

    const handleIncrement = () => {
        Load()
        if (_mintAmount >= qty) return;
        setMintAmount(_mintAmount + 1);
    }
    async function connectAccount(){
        if (window.ethereum){
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            setAccounts(accounts);
            localStorage.setItem('Wallet', accounts);
        }
    }
    async function openRoadmap(){
        window.location.hash = "#roadmap";
        document.getElementById("openrd").style.display='none'; 
        document.getElementById("rdmp").style.display='block';
    }
    async function closeRoadmap(){
        window.location.hash = "#rarity";
        document.getElementById("openrd").style.display='flex';
        setTimeout(() => {
            console.log("1 Segundo esperado")
          }, 10000);
        document.getElementById("rdmp").style.display='none';
    }


    return(

        <div class="minting">
            <Flex>
                <div class="separator1">

                </div>

            </Flex>
            <Flex direction='column'>
                <div class="grid">
                    <div class="one notbg" id="about">
                        <h1>What is the Turtle Club?</h1>
                        <br></br>
                        <p>Turtle Club is an NFT collection of 10,000 amazing turtles eggs about to hatch. Trying to make a change as a bridge from the metaverse to IRL</p>
                        <br></br>
                        <p>Mixed into this turtles there are 15 unique turtles one of one. Those in possesion of those one of one would be able to conect metaverse and real life like never before.</p>
                    </div>
                    <svg class="one" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#3f806c" fill-opacity="1" d="M0,160L48,170.7C96,181,192,203,288,176C384,149,480,75,576,48C672,21,768,43,864,74.7C960,107,1056,149,1152,144C1248,139,1344,85,1392,58.7L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
                    <div class="two notbg" id="rarity">
                        <h1>Rarity</h1>
                        <br></br>
                        <p>Some text and maybe a graph or a table explaining the rarity of the proyect.</p>
                    </div>
                    <svg class="two" id="rd" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#589552" fill-opacity="1" d="M0,256L48,218.7C96,181,192,107,288,90.7C384,75,480,117,576,122.7C672,128,768,96,864,80C960,64,1056,64,1152,74.7C1248,85,1344,107,1392,117.3L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
                    <div class="three notbg" id="roadmap">
                        <h1>Roadmap</h1>
                        <br></br>
                        <Button
                        _hover={{minWidth:"250px", color:"#649f5f", backgroundColor:"white"}}
                        fontSize="16px"
                        onClick={openRoadmap}
                        backgroundColor="#649f5f"
                        borderRadius="25px"
                        borderWidth="0"
                        color="white"
                        height="40px"
                        transition="1s linear"
                        width="auto"
                        minWidth="150px"
                        paddingTop="10px"
                        cursor="pointer"
                        fontFamily="inherit"
                        padding="15px"
                        margin="15px 15px"
                        id='openrd' > 
                        View Roadmap 
                        </Button> 
                    <br></br>
                    <span id="rdmp">
                    <img src={Roadmap} id="Roadmap" alt="Roadmap" />
                    <br></br>
                    <Button
                        _hover={{minWidth:"250px", color:"#649f5f", backgroundColor:"white"}}
                        fontSize="16px"
                        onClick={closeRoadmap}
                        backgroundColor="#649f5f"
                        borderRadius="25px"
                        borderWidth="0"
                        color="white"
                        height="40px"
                        transition="1s linear"
                        width="auto"
                        minWidth="150px"
                        paddingTop="10px"
                        cursor="pointer"
                        fontFamily="inherit"
                        padding="15px"
                        margin="15px 15px"> 
                        Close
                        </Button>
                    </span> 
                </div>
                <svg class="three" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#98c159" fill-opacity="1" d="M0,256L48,234.7C96,213,192,171,288,128C384,85,480,43,576,69.3C672,96,768,192,864,218.7C960,245,1056,203,1152,160C1248,117,1344,75,1392,53.3L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
                <div class="four notbg" id="team">
                    <h1>Team</h1><br></br>
                    <p></p>
                    
                    
                    
                </div>
                <svg class="four" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#e4ce93" fill-opacity="1" d="M0,320L48,320C96,320,192,320,288,293.3C384,267,480,213,576,186.7C672,160,768,160,864,170.7C960,181,1056,203,1152,208C1248,213,1344,203,1392,197.3L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
                <div class="five notbg" id="faq">
                    <h1>FAQ</h1><br></br>
                    <div class="faq">
                        <details>
                            <summary>What is an NFT?</summary>
                            <br></br>
                                NFTs ("Non-Fungible Tokens") are one-of-a-kind tokens that represent a unique good or asset, like digital art. They can be sold in to collectors and the sale of NFTs. NFTs created on Mint are hosted on the Flow blockchain.                            
                        </details>
                        <details>
                            <summary>How can I join the community?</summary>
                            <br></br>
                            We have an awesome community and new members are always welcome! Join our Turtle community on <a href="#Discord">Discord</a>.
                        </details>
                        <details>
                            <summary>How many NFT’s are available?</summary>
                            <br></br>
                            The Turtle Club collection is limited to 10,000 Turtles.
                        </details>
                        <details>
                            <summary>When can I mint my Turtle?</summary>
                            <br></br>
                            The launch date is still TBA.
                        </details>
                        <details>
                            <summary>What is the minting Price?</summary>
                            <br></br>
                            The mint price is still TBA
                        </details>
                        <details>
                            <summary>Is your contract gas efficient?</summary>
                            <br></br>
                            Yes, we will use ERC-721A, it means you can mint up to 3 NFTs per tx very cheap.
                        </details>
                        
                    </div>
                </div>
                <svg class="five" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#7d8184" fill-opacity="1" d="M0,160L48,176C96,192,192,224,288,218.7C384,213,480,171,576,154.7C672,139,768,149,864,149.3C960,149,1056,139,1152,128C1248,117,1344,107,1392,101.3L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
                </div>
                <footer class="footer-07">
                        <div class="container">
                        <div class="row justify-content-center">
                        <div class="col-md-12 text-center">
                        <h4 class="footer-heading hide"><a href="#" class="logo hide">theturtleclub.io</a></h4>
                        <img src={shells} id="shells" alt="Shells" />
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-md-12 text-center">
                        <p class="copyright">
                        Copyright ©<script>document.write(new Date().getFullYear());</script>2022 All rights reserved | Developed with <i class="material-icons" id="corazon">favorite</i> by <a class="link" href="https://twitter.com/ennzueth" target="_blank">Ennzu.eth</a>
                        </p>
                        </div>
                        </div>
                        </div>
                </footer>
            </Flex>   
        </div>
    );
};

export default MainMint;